<template>
  <div class="p-3">
    <div class="flex justify-between flex-wrap justify-sm-center justify-xs-center">
      <SelectComponent class="w-64 m-1" />
      <SearchBox class="w-64 p-2 m-1" />
    </div>
    <Table :items="stMaterialList" hover-action>
      <template slot="row" slot-scope="{ item }" md-selectable="single">
        <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
        <md-table-cell md-label="F_ID" class="text-victoria">{{ item.fId }}</md-table-cell>
        <md-table-cell md-label="TITLE">
          {{ item.title }}
          <span v-if="item.id == 2" class="ml-2 bg-tahiti-gold text-white px-1 rounded">BULK</span>
        </md-table-cell>
        <md-table-cell md-label="FILE TYPE">{{ item.fileType }}</md-table-cell>
        <md-table-cell md-label="USED FOR">{{ item.usedFor }}</md-table-cell>
        <md-table-cell md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
        <md-table-cell md-label="CREATED BY" class="text-victoria">{{ item.createdBy }}</md-table-cell>
        <md-table-cell md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
        <span class="action">
              <md-icon
                class="bg-default rounded-full p-1 text-gray-700 text-base mr-1"
              >visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base ">edit</md-icon>
            </span>
      </template>
    </Table>
  </div>
</template>

<script>
import SearchBox from "@/components/atom/SearchBox";
import { SelectComponent, Table } from "@/components";
import data from "@/data/material/data"
export default {
  name: "Todo-List",
  components: {
    Table,
    SearchBox,
    SelectComponent,
  },
  data() {
    return {
      stMaterialList: data.stMaterialList
    }
  },
  methods: {
    onSelect() {}
  }
};
</script>

